import React from 'react';
import styled from 'styled-components';
import './styles.css';

import { base } from 'services/common';
import fieldsOfWork from 'constants/fieldsOfWork';
import targetAudience from 'constants/targetAudience';
import { addLinkToText } from 'utils/addLinkToText';

const EventStyled = styled.article`
  display: flex;
  margin: 133px;
  margin-top: 80px;
  justify-content: space-between;

  & img {
    max-height: 500px;
    border-radius: 17px;
    margin-right: 250px;
  }

  @media (max-width: 1530px) {
    & img {
      margin-right: 150px;
    }
  }

  @media (max-width: 1440px) {
    & img {
      margin-right: 50px;
    }
  }

  @media (max-width: 1300px) {
    & img {
      margin-right: 0;
    }
  }

  @media (max-width: 1100px) {
    & img {
      max-height: 450px;
    }
  }

  @media (max-width: 1000px) {
    & img {
      max-height: 400px;
    }
  }

  @media (max-width: 900px) {
    margin: 60px;
  }

  @media (max-width: 768px) {
    & img {
      max-height: 380px;
    }
  }

  @media (max-width: 710px) {
    flex-direction: column;

    & img {
      margin: 0;
      margin-top: 50px;
      max-height: none;
      width: 60%;
    }
  }

  @media screen and (max-width: 450px) {
    margin: 40px;
  }

  @media screen and (max-width: 350px) {
    margin: 30px;
  }

  @media screen and (max-width: 330px) {
    margin: 20px;
  }
`;

const EventChit = styled.span`
  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor} !important`}
`;

const Event = ({ event }) => {
  return (
    <EventStyled>
      <div className="event__content">
        <div className="event__metadata">
          <span className="event__year">{new Date(event.dateOfBeginning).getFullYear()}</span>
          {event.targetAudience && (
            <span className="event__chit event__chit--audience">{targetAudience[event.targetAudience]}</span>
          )}
          {event.theme && (
            <EventChit
              className="event__chit event__chit--field"
              backgroundColor={fieldsOfWork[event.theme].backgroundColor.substring(0, 7) + '90'}
            >
              {fieldsOfWork[event.theme].displayName}
            </EventChit>
          )}
        </div>
        <h2 className="event__title events__popular--title">{event.name}</h2>
        <p className="event__description" dangerouslySetInnerHTML={{__html: addLinkToText(event.shortDescription)}}></p>
      </div>
      <img src={`${base}/images/${event.posterImagePath.folder}/${event.posterImagePath.image}`} alt={event.name} />
    </EventStyled>
  );
};

export default Event;
