import React from 'react';

import AlumnusMemberDetails from './AlumnusMemberDetails';
import { base } from 'services/common';
import ImageLoader from 'components/common/ImageLoader';

const AlumnusMember = ({ member }) => {
  const setFallbackImageSource = event => {
    event.target.src = './images/members/alumni/fallback.png';
  };

  return (
    <div className='list__alumnus-wrapper'>
      <div className={`list__alumnus ${member.isDeceased && 'list__alumnus--deceased'}`}>
        <ImageLoader
          src={`${base}/images/${member.alumniImagePath.folder}/${member.alumniImagePath.image}`}
          onError={setFallbackImageSource}
          className='list__alumnus-image'
          alt={`${member.firstName} ${member.lastName}`}
        />
      </div>
      <AlumnusMemberDetails member={member} />
    </div>
  );
};

export default AlumnusMember;
