import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'infinite-react-carousel';
import SkolaOsnova from 'assets/shapes/skola_osnova.png';

import AttendanceInfo from './AttendanceInfo';
import { base } from 'services/common';
import { filterEventsByName } from 'utils/filterEventsByName';
import ImageLoader from 'components/common/ImageLoader';
import { normalizeForUrl } from 'utils/normalizeForUrl';

const EventFigure = ({ event, isSliding }) => {
  return (
    <Link
      rel="noopener"
      to={{
        pathname: `/events/${event.id}-${normalizeForUrl(event.name)}`,
        state: { event },
      }}
      className={`${isSliding ? 'grabbing' : ''}`}
    >
      <figure>
        <ImageLoader
          src={`${base}/images/${event.posterImagePath.folder}/${event.posterImagePath.image}`}
          alt="poster"
        />
        <figcaption>{new Date(event.dateOfBeginning).getFullYear()}</figcaption>
      </figure>
      <link rel="preload" href={`${base}/images/${event.posterImagePath.folder}/${event.posterImagePath.image}`} as="image" />
    </Link>
  );
};

const SopEvents = ({ allSopEvents }) => {
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const sliderRef = useRef();

  const carouselSettings = {
    arrows: false,
    arrowsBlock: false,
    autoplay: true,
    centerPadding: 10,
    duration: 300,
    shift: 10,
    className: 'events__carousel',
    pauseOnHover: false,
  };

  const mockScroll = () => {
    document.dispatchEvent(new CustomEvent('scroll'));
  };

  const handlePointerDown = () => {
    sliderRef.current && sliderRef.current.slickPause();
    setIsSliding(true);
  };

  const handlePointerUp = () => {
    sliderRef.current && sliderRef.current.slickPlay();
    setIsSliding(false);
  };

  return (
    <div className="events__popular__wrapper">
      <div className="events__popular__stack">
        <ImageLoader className="events__popular--sop" src={SkolaOsnova} alt="SOP" />
        <div className="events__popular--carousel" onPointerDown={handlePointerDown} onPointerUp={handlePointerUp}>
          {allSopEvents && allSopEvents.length > 0 && (
            <Slider {...carouselSettings} afterChange={setCurrentEventIndex} beforeChange={mockScroll} ref={sliderRef}>
              {allSopEvents.map((event, index) => (
                <div key={index} className="event__carousel__poster">
                  <EventFigure key={index} event={event} isSliding={isSliding} />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
      <div className="sop--event events__popular--content">
        <h3>Škola osnova programiranja</h3>
        <h2 className="events__popular--title">Tradicija duga desetljeće</h2>
        <p>
          Škola osnova programiranja naš je najstariji i najpoznatiji ciklus predavanja. Već puno desetljeće na početku
          akademske godine organiziramo ovaj događaj kako bismo svoje znanje prenijeli na mlade entuzijaste i naučili ih
          ovoj korisnoj vještini. Naša predavanja uvijek su besplatna, a predznanje nije potrebno.
        </p>

        <AttendanceInfo eventAttendance="Sop" margin="64px 0 64px 0" />

        {allSopEvents && allSopEvents[currentEventIndex] && (
          <Link
            rel="noopener"
            to={{
              pathname: `/events/${allSopEvents[currentEventIndex].id}-${normalizeForUrl(allSopEvents[currentEventIndex].name)}`,
              state: { event: allSopEvents[currentEventIndex] },
            }}
          >
            <button className="events__popular--button">Vidi događaj</button>
          </Link>
        )}
      </div>
    </div>
  );
};

const DumpDays = ({ allDumpDays }) => {
  let latestDumpDays = null;
  if (allDumpDays && allDumpDays.length) {
    latestDumpDays = allDumpDays[0];
  }

  return (
    <div className="ddays--event events__popular--content">
      <h3>DUMP Days Konferencija</h3>
      <h2 className="events__popular--title">Dev, Design & Tech Konferencija</h2>
      <p>
        DUMP Days je konferencija koja na jednom mjestu okuplja najutjecajnije ljude s hrvatske IT scene - predstavnike
        tvrtki, profesionalce i studente, pružajući brojne prilike za sklapanje kvalitetnih poznanstava i poslovnih
        suradnji.
      </p>

      <AttendanceInfo eventAttendance="DumpDays" margin="64px 0 64px 0" />

      {latestDumpDays && (
        <Link rel="noopener" to={`/events/${latestDumpDays.id}-${normalizeForUrl(latestDumpDays.name)}`}>
          <button className="events__popular--button">Vidi događaj</button>
        </Link>
      )}
    </div>
  );
};

const PopularEvents = ({ allEvents }) => {
  return (
    <section className="events__popular">
      <SopEvents allSopEvents={filterEventsByName(allEvents, 'škola osnova programiranja')} />
      <DumpDays allDumpDays={filterEventsByName(allEvents, 'dump days')} />
    </section>
  );
};

export default PopularEvents;
