import React from 'react';

import { base } from 'services/common';
import { togglePublishProjectById } from 'services/admin';
import fieldsOfWork from 'constants/fieldsOfWork';

import TextElement from '../TextElement';
import { Link } from 'react-router-dom';

import ProjectStyled from './index.styled';
import { useWindowSize } from 'utils/media';
import { normalizeForUrl } from 'utils/normalizeForUrl';

const calculateOffsetY = (width, left) => {
  return ((-1.5 * width + 500) * left) / 1200 + 20;
};

const calculateOffsetX = (width, top) => {
  return width < 600 ? (200 * top) / 100 - 4 : 0;
};

const ProjectBackgroundImage = ({ folder, path, alt }) => {
  return <img className="project__styled__img" src={`${base}/images/${folder}/${path}`} alt={alt} />;
};

const ProjectDetails = ({ project }) => {
  return (
    <>
      <ProjectBackgroundImage folder={project.imagePath.folder} path={project.imagePath.image} alt={project.name} />
      <div className="project__styled__text">
        <div className="project__styled__skills">
          {project.projectSkills.map((projectSkill, index) => (
            <div className="project__styled__skill" key={index}>
              {fieldsOfWork[projectSkill.skill].displayName}
            </div>
          ))}
        </div>
        <TextElement text={project.title}></TextElement>
        <TextElement text={project.shortDescription} />
      </div>
    </>
  );
};

const ProjectDisplay = ({ project, isEdit }) => {
  if (isEdit) {
    return <ProjectDetails project={project} />;
  }

  return (
    <Link rel="noopener" to={`/projects/${project.id}-${normalizeForUrl(project.name)}`}>
      <ProjectDetails project={project} />
    </Link>
  );
};

const Project = ({ project, isPreview, setEditingIndex, index, isEdit, refreshProjects, isFirst }) => {
  const windowSize = useWindowSize();

  const togglePublishProject = () => {
    if (!window.confirm(`Are you sure you want to ${project.isPublished ? 'un' : ''}publish this project`)) return;
    togglePublishProjectById({ projectId: project.id }).then(() => refreshProjects());
  };

  return (
    <ProjectStyled
      {...project.backgroundImageOffset}
      {...project.ctaTextCoordinates}
      projectSkillsBackgroundColor={project.projectSkillsBackgroundColor}
      ctaTextAlignment={project.ctaTextAlignment}
      isPreview={isPreview}
      isFirst={isFirst}
      marginTop={project.backgroundImageOffset.marginTop !== 0 && project.backgroundImageOffset.marginTop}
      offsetLeft={calculateOffsetY(windowSize[0], project.ctaTextCoordinates.left)}
      offsetTop={calculateOffsetX(windowSize[0], project.ctaTextCoordinates.top)}
    >
      {!isEdit && <ProjectDisplay project={project} isEdit={isEdit} />}
      {isEdit && (
        <>
          <div className="button-container">
            <button className="button-admin submit-button" onClick={() => togglePublishProject()}>
              {project.isPublished ? 'Unpublish' : 'Publish'}
            </button>
            <button className="button-admin submit-button" onClick={() => setEditingIndex(index)}>
              Edit project
            </button>
            <Link rel="noopener" to={`/admin/builders/project/project-sections/${project.id}`}>
              <button className="button-admin submit-button">Edit case study</button>
            </Link>
          </div>
          {project.imagePath.folder && <ProjectDisplay project={project} isEdit={isEdit} />}
        </>
      )}
    </ProjectStyled>
  );
};

export default Project;
