import React from 'react';
import { base } from 'services/common';
import ImageLoader from 'components/common/ImageLoader';

const Images = ({ post }) => {
  const setFallbackImageSource = event => {
    event.target.src = './images/posts/fallback.png';
  };

  const mainImageSource = `${base}/images/${post.mainImagePath.folder}/${post.mainImagePath.image}`;
  const secondaryImageSource = `${base}/images/${post.secondaryImagePath.folder}/${post.secondaryImagePath.image}`; 

  return (
    <div className="post__images">
      <ImageLoader
        className="images__main"
        src={mainImageSource}
        onError={setFallbackImageSource}
        alt=""
      />
      <link rel="preload" href={secondaryImageSource} as="image" />
      <ImageLoader
        className="images__secondary"
        src={secondaryImageSource}
        onError={setFallbackImageSource}
        alt=""
      />
      <link rel="preload" href={secondaryImageSource} as="image" />
    </div>
  );
};

export default Images;
