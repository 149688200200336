import axios from 'axios';
import { history } from '../utils/BrowserHistoryWrapper';

export const base = ''; // Leave empty now that app is integrated. If it is necessary to point to some other backend url, insert it here.

const userBase = `${base}/user`;
const trustedPartnerBase = `${base}/trusted-partners`;
const contactQuestionInfoBase = `${base}/contact-question-info`;
const contactQuestionBase = `${base}/contact-question`;
const configurationBase = `${base}/configurations`;
const memberBase = `${base}/member`;
const libraryBase = `${base}/library`;
const projectBase = `${base}/project`;
const projectSectionsBase = `${base}/project-section`;
const postBase = `${base}/post`;
const eventBase = `${base}/event`;
const enumBase = `${base}/enum`;
const zipBase = `${base}/zip`;

//TODO: Separate all of these endpoints by admin and client, leave as mess for now
export const endpoints = {
  admin: {
    login: `${base}/login`,
    newToken: `${base}/login/new`,
    user: {
      add: `${userBase}/add`,
      edit: `${userBase}/edit`,
      changePassword: `${userBase}/change-password`,
      getUsers: `${userBase}/get-users`,
      setIsUserActive: `${userBase}/set-is-active`,
      getLogs: `${userBase}/get-logs`,
    },
    contactQuestionInfo: {
      add: `${contactQuestionInfoBase}/add`,
      getContactQuestionInfos: `${contactQuestionInfoBase}/get`,
      getActiveContactQuestionInfos: `${contactQuestionInfoBase}/get-active`,
      setIsQuestionActive: `${contactQuestionInfoBase}/active`,
      setDoesHaveNewsletterOption: `${contactQuestionInfoBase}/newsletter`,
      editText: `${contactQuestionInfoBase}/edit-text`,
    },
    contactQuestion: {
      add: `${contactQuestionBase}/add`,
      getContactQuestions: `${contactQuestionBase}/get`,
      sendEmailBatch: `${contactQuestionBase}/email-batch`,
      getNewsletterPreferences: `${contactQuestionBase}/newsletter-preferences`,
      setNewsletterPreferences: `${contactQuestionBase}/set-newsletter-preferences`,
      getNewsletterSubscribers: `${contactQuestionBase}/get-subscribers`,
    },
    trustedPartner: {
      getAll: `${trustedPartnerBase}`,
      add: `${trustedPartnerBase}/add`,
      edit: `${trustedPartnerBase}/edit`,
      delete: `${trustedPartnerBase}/delete`,
    },
    configuration: {
      get: `${configurationBase}/get`,
      add: `${configurationBase}/add`,
      edit: `${configurationBase}/edit`,
    },
    project: {
      save: `${projectBase}/save`,
      edit: `${projectBase}/edit`,
      togglePublish: `${projectBase}/toggle-publish`,
      editSort: `${projectBase}/edit-sort`,
      setProjectSkills: `${projectBase}/set-project-skills`,
    },
    projectSections: {
      save: `${projectSectionsBase}/save`,
    },
    event: {
      add: `${eventBase}/add`,
      edit: `${eventBase}/edit`,
      delete: `${eventBase}/delete`,
      deleteFile: `${eventBase}/delete-file`,
      getActive: `${eventBase}/get-active`,
    },
    enum: {
      getFieldOfWorkTypes: `${enumBase}/get-field-of-work-types`,
      getTargetAudienceTypes: `${enumBase}/get-target-audience-types`,
      getUserActionTypes: `${enumBase}/get-user-action-types`,
    },
    zip: {
      upload: `${zipBase}/upload`,
      delete: `${zipBase}/delete`,
    },
  },
  event: {
    getAll: `${eventBase}/get-all`,
    getEventById: `${eventBase}`,
    getActive: `${eventBase}/get-active`,
    getLatestEventImages: `${eventBase}/event-posters`,
  },
  project: {
    get: `${projectBase}`,
    getAll: `${projectBase}/get-all`,
    getAllPublished: `${projectBase}/get-all-published`,
    takePublished: `${projectBase}/take-published`,
    getPublished: `${projectBase}/get-published`,
  },
  projectSections: {
    get: `${projectSectionsBase}/get`,
  },
  member: {
    get: `${memberBase}`,
    getAll: `${memberBase}/get-all`,
    add: `${memberBase}/add`,
    edit: `${memberBase}/edit`,
    delete: `${memberBase}/delete`,
    getMemberTypeEnum: `${memberBase}/member-type-enum`,
  },
  library: {
    upload: `${libraryBase}/upload`,
    uploadPlaceHolder: `${libraryBase}/upload-placeholder`,
    delete: `${libraryBase}/delete`,
    getAll: `${libraryBase}/get-all`,
    uploadCaseStudyImageGallery: `${libraryBase}/upload-case-study-image-gallery`,
  },
  post: {
    getNext: `${postBase}/get-next`,
    getPrevious: `${postBase}/get-previous`,
    get: `${postBase}`,
    getAll: `${postBase}`,
    add: `${postBase}/add`,
    edit: `${postBase}/edit`,
    delete: `${postBase}/delete`,
  },
};

const newToken = token => {
  return axios.post(endpoints.admin.newToken, { token });
};

export const configureAxios = () => {
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  axios.interceptors.request.use(config => {
    const token = `Bearer ${localStorage.getItem('token')}`;
    config.headers['Authorization'] = token;

    return config;
  });

  const handleRedirectToLogin = () => {
    localStorage.removeItem('token');
    history.push('/admin/login');
  };

  axios.interceptors.response.use(
    response => response,
    error => {
      let originalRequest = error.config;
      const token = localStorage.getItem('token');
      if (error.response && error.response.status === 401 && !token) {
        history.push('/admin/login');

        return Promise.reject(error);
      } else if (
        error.response &&
        error.response.status === 401 &&
        originalRequest &&
        !originalRequest._isRetryRequest &&
        token
      ) {
        originalRequest._isRetryRequest = true;

        return newToken(token).then(({ data }) => {
          if (!data) {
            handleRedirectToLogin();

            return;
          }
          localStorage.setItem('token', data);

          return axios(originalRequest);
        });
      } else if (
        error.response &&
        error.response.status === 401 &&
        originalRequest &&
        originalRequest._isRetryRequest
      ) {
        handleRedirectToLogin();
      }

      return Promise.reject(error);
    },
  );
};
