import React, { useRef, useState, useEffect } from 'react';
import '../styles.css';
import { useDrag, useDrop } from 'react-dnd';

import { sortLogic } from 'utils/sortLogic';
import { DraggableTypes } from 'constants/sectionType';
import { base } from 'services/common';
import {
  getStyledComponentProps,
  hasImage,
  isImageOnly,
  isTripleTextEditor,
  isSingleTextEditor,
  isImageGallery,
} from 'services/projectSectionLogic';

import ProjectSectionEditor from './ProjectSectionEditor';
import TextElement from 'components/common/TextElement';
import { getTextEditorValue } from 'components/common/ProjectDetailsDisplay';
import ProjectSectionStyled from 'components/styled/ProjectSectionStyled';

const ProjectSection = ({
  index,
  item,
  moveSection,
  isSorting,
  setProjectSections,
  projectSections,
  findSection,
  isNew,
  setAddedIndex,
}) => {
  const ref = useRef(null);
  const { section } = findSection(`${item.sectionOrder}`);
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const originalIndex = findSection(index).index;

  const handleOutsideClick = ({ target }) => {
    if (ref.current && !ref.current.contains(target) && isNew) {
      setIsEditing(false);
      setAddedIndex(undefined);
    } else if (ref.current && !ref.current.contains(target)) {
      // DO NOT TOUCH
      // ante@dump.hr or alex@dump.hr
      // for further info
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: DraggableTypes.section, index, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (dropResult, monitor) => {
      const { index: droppedIndex, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) moveSection(originalIndex, droppedIndex);
    },
  });

  const [, drop] = useDrop({
    accept: DraggableTypes.section,
    canDrop: () => false,
    hover({ index: draggedIndex }, monitor) {
      if (!ref.current) return;
      const { index: overIndex } = findSection(index);
      if (!sortLogic(draggedIndex, overIndex, ref, monitor)) return;

      moveSection(overIndex, draggedIndex);
    },
  });

  const deleteSection = index => {
    const { index: locatedIndex } = findSection(index);
    const sectionsNew = [...projectSections];
    sectionsNew.splice(locatedIndex, 1);
    setProjectSections([...sectionsNew]);
  };

  const handleSetSectionTitle = ({ target: { value } }) => {
    const projectSectionsNew = projectSections;
    projectSectionsNew.splice(index, 1);
    projectSectionsNew.splice(index, 0, { ...section, title: value });
    setProjectSections([...projectSectionsNew]);
  };

  const visibility = isDragging ? 'hidden' : 'visible';

  if (isEditing || isNew)
    return (
      <ProjectSectionEditor
        ref={ref}
        key={index}
        item={item}
        setItems={setProjectSections}
        projectSections={projectSections}
        findItem={findSection}
      />
    );

  drag(drop(ref));

  return (
    <ProjectSectionStyled
      className="project-section__preview"
      {...getStyledComponentProps(item)}
      ref={ref}
      key={index}
      visibility={visibility}
      isSorting={isSorting}
      isExpanded={isExpanded}
      isTripleTextEditor={isTripleTextEditor(item)}
      isSingleTextEditor={isSingleTextEditor(item)}
      isImageGallery={isImageGallery(item)}
      isEditor
    >
      <div className="droppable-section">
        <div>
          <span>Sort above me</span>
        </div>
        {item.title}
        <div>
          <span>Sort under me</span>
        </div>
      </div>
      <div className="control-section">
        <button
          className="button-admin edit-button"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          Edit section
        </button>
        <button className="button-admin cancel-button" onClick={() => deleteSection(index)}>
          Delete section
        </button>
        <button className="button-admin expand-button" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <div>Collapse &#708;</div> : <div>Expand &#709;</div>}
        </button>
      </div>
      {!isExpanded && (
        <div className="project__section__title">
          <label className="label">Title</label>
          <input className="input" type="text" value={item.title} onChange={handleSetSectionTitle} />
        </div>
      )}
      {!isImageOnly(item) && isExpanded && (
        <div className="project-section__text">
          <div className="project-section__text__wrapper">
            <TextElement text={getTextEditorValue(item, 0)} />
          </div>
          {isTripleTextEditor(item) && (
            <>
              <div className="project-section__text__wrapper">
                <TextElement text={getTextEditorValue(item, 1)} />
              </div>
              <div className="project-section__text__wrapper">
                <TextElement text={getTextEditorValue(item, 2)} />
              </div>
            </>
          )}
        </div>
      )}
      {hasImage(item) && isExpanded && (
        <>
          <div className="project-section__image__wrapper">
            <img src={`${base}/images/${item.imagePath.folder}/${item.imagePath.image}`} alt={item.imagePath.image} />
          </div>
          {isImageGallery(item) && (
            <>
              {item.text.map((textImage, index) => {
                return (
                  <div className="project-section__image__wrapper" key={index}>
                    <img src={`${base}/images/${textImage.folder}/${textImage.image}`} alt={textImage.image} />
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </ProjectSectionStyled>
  );
};

export default ProjectSection;
