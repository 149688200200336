import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';

import LibraryPicker from './LibraryPicker';
import FileBox from '../FileBox';

import { uploadToPlaceholder, uploadToCaseStudyImageGallery } from 'services/admin';
import { FileType } from 'constants/fileType';
import { base } from 'services/common';
import { ImagePathLogic } from 'services/imagePathLogic';

const ImagePickerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  border: 2px solid #d8d8d8;
`;

const ImagePreviewStyled = styled.div`
  position: relative;

  & > .cancel-button {
    position: absolute;
    top: 10px;
    left: 10px;
  }
`;

const ImagePicker = ({ imagePathChangedCallback, imagePath, className, isTextImage, imagePathFallback }) => {
  const [isPreviewMode, setIsPreviewMode] = useState(imagePath && imagePath.folder && imagePath.image);
  const [currentImagePath, setCurrentImagePath] = useState(imagePath);

  useEffect(() => {
    if (imagePath.folder === 'Default') setIsPreviewMode(false);
  }, [imagePath]);

  const imageDropped = useCallback(
    formFile => {
      if (isTextImage) {
        uploadToCaseStudyImageGallery(formFile)
        .then(({ data }) => {
          const currentImage = {
            folder: 'case-study-image-gallery',
            image: data,
          };
          setCurrentImagePath(currentImage);
          setIsPreviewMode(true);
          imagePathChangedCallback(currentImage);
        })
        .catch(e => {
          window.alert("Uploaded image is not of accepted format.")
        });
      } else {
        uploadToPlaceholder(formFile).then(({ data }) => {
          const currentImage = {
            folder: 'placeholder',
            image: data,
          };
          setCurrentImagePath(currentImage);
          setIsPreviewMode(true);
          imagePathChangedCallback(currentImage);
        })
        .catch(e => {
          window.alert("Uploaded image is not of accepted format.")
        });
      }
    },
    [imagePathChangedCallback, isTextImage],
  );

  const imagePickedLibrary = useCallback(
    image => {
      const currentImage = {
        folder: 'library',
        image,
      };
      setCurrentImagePath(currentImage);
      setIsPreviewMode(true);
      imagePathChangedCallback(currentImage);
    },
    [imagePathChangedCallback],
  );

  const cancelImage = () => {
    setIsPreviewMode(false);
    if (!!imagePathFallback) {
      setCurrentImagePath({ ...imagePathFallback });
      imagePathChangedCallback({ ...imagePathFallback });
    } else {
      setCurrentImagePath(new ImagePathLogic().imagePath);
      imagePathChangedCallback(new ImagePathLogic().imagePath);
    }
  };

  if (isPreviewMode)
    return (
      <ImagePreviewStyled className={className}>
        <img src={`${base}/images/${currentImagePath.folder}/${currentImagePath.image}`} alt={currentImagePath.image} />
        <button className="button-admin cancel-button" onClick={cancelImage}>
          Cancel
        </button>
      </ImagePreviewStyled>
    );

  return (
    <ImagePickerStyled className={className}>
      <FileBox fileType={FileType.photo} onDrop={imageDropped} />
      {!isTextImage && <LibraryPicker pickedCallback={imagePickedLibrary} />}
    </ImagePickerStyled>
  );
};

export default ImagePicker;
