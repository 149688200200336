import React, { forwardRef } from 'react';

import ActiveMemberDetails from './ActiveMemberDetails';
import { base } from 'services/common';
import ImageLoader from 'components/common/ImageLoader';

const ActiveMember = forwardRef(({ member, idOfSelectedMember, memberClickHandler }, memberRef) => {
  const isSelected = member.id === idOfSelectedMember;

  const setFallbackImageSource = event => {
    event.target.src = './images/members/active/fallback.png';
  };

  return (
    <div className="list__active" onClick={e => memberClickHandler(member.id, e)} ref={isSelected ? memberRef : null}>
      <ImageLoader
        src={`${base}/images/${member.activeImagePath.folder}/${member.activeImagePath.image}`}
        onError={setFallbackImageSource}
        className={`active__image ${isSelected && 'active__image--scaled'}`}
        alt=""
      />
      {isSelected && <ActiveMemberDetails member={member} />}
    </div>
  );
});

export default ActiveMember;
